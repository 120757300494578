import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';

import {
    LoadingGif,
    ic4,
    ic5,
    ic6
} from "../../images";

const QType19 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return (
            <div>
                <img src={ic4} style={{display: 'none'}} />
                <img src={ic5} style={{display: 'none'}} />
                <img src={ic6} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
            </div>
        )
    }

    return (
        <>
            <div className='question active' >
                <p className={`questionName mbs wow delay ${isLoading ? '' : 'animate'}`}>
                    {parse(quesKey.name)}
                </p>
                <img src={quesKey.image} alt="" className={`questionImageFull wow delay01 ${isLoading ? '' : 'animate'}`} />
                <div className={`questionText2 tac wow delay11 ${isLoading ? '' : 'animate'}`}>
                    <p>{parse(quesKey.text)}</p>
                </div>
                <div className="questionAdvas">
                    <div className={`questionAdva purple left wow2 delay21 ${isLoading ? '' : 'animate'}`}>
                        <span>Hidden financial losses</span>
                        <img src={ic4} alt="" />
                    </div>
                    <div className={`questionAdva orange right wow3 delay31 ${isLoading ? '' : 'animate'}`}>
                        <span>Distribute sources of income</span>
                        <img src={ic5} alt="" />
                    </div>
                    <div className={`questionAdva blue center wow2 delay41 ${isLoading ? '' : 'animate'}`}>
                        <span>Plan your budget correctly</span>
                        <img src={ic6} alt="" />
                    </div>
                </div>
            </div>
        </>     
    )
}

export default memo(QType19);
