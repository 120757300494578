import '../../styles/pages/question.scss';
import React, {memo, useState, useCallback, useRef, useEffect} from 'react';
import ReactCanvasConfetti from "react-canvas-confetti";

import {
    LoadingGif,
    girl
} from "../../images";

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

const QType10 = () => {

    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
        refAnimationInstance.current({
            ...opts,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio)
        });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
        spread: 26,
        startVelocity: 55
        });

        makeShot(0.2, {
        spread: 60
        });

        makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
        });

        makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
        });

        makeShot(0.1, {
        spread: 120,
        startVelocity: 45
        });
    }, [makeShot]);

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false);
    }, 300);

    useEffect(() => {
        fire();
    });

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return <img src={girl} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
    }

    return (
        <>
            <div className='question trial quesTrial active' >
                <span className={`wow delay ${isLoading ? '' : 'animate'}`}>You receive a <span>7-day trial</span></span>
                <p className={`wow delay01 ${isLoading ? '' : 'animate'}`}>Hooray! Your personal strategy is ready - <br /> register to try it for $1</p>
                <img src={girl} alt="" className="quesTrialGirl" />
            </div>

            <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        </>     
    )
}

export default memo(QType10);
