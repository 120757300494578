import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';

import {
    LoadingGif,
    starComment
} from "../../images";

const QType15 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return <img src={starComment} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
    }

    return (
        <>
            <div className='question active' >
                {/* <p className="questionName">
                    {qKey.name}
                </p> */}
                <div className={`questionComm2 wow delay ${isLoading ? '' : 'animate'}`}>
                    <div className="questionComm2Image">
                        <img src={quesKey.image} alt="" />
                    </div>
                    <div className="questionStars">
                        <img src={starComment} alt="" />
                        <img src={starComment} alt="" />
                        <img src={starComment} alt="" />
                        <img src={starComment} alt="" />
                        <img src={starComment} alt="" />
                    </div>
                    <p className="questionComm2Name">{quesKey.name}</p>
                    <p className="questionComm2Text">{quesKey.text}</p>
                    
                </div>
                
            </div>
        </>     
    )
}

export default memo(QType15);
