import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';

import {
    LoadingGif
} from "../../images";

const QType5 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return <img src={quesKey.image} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
    }

    return (
        <>
            <div className='question active' >
                <p className={`questionName medium wow delay ${isLoading ? '' : 'animate'}`}>
                    {parse(quesKey.name)}
                </p>
                {
                    (quesKey.descr.length > 0) && (
                        <p className="questionDescr">
                            {parse(quesKey.descr)}
                        </p>
                    )
                }
                <div className={`questionText wow delay01 ${isLoading ? '' : 'animate'}`}>
                    <ul>
                        {
                            quesKey.steps.map( (key, index) => (
                                <li key={index}>{parse(key)}</li>
                            ))
                        }
                    </ul>
                </div>
                <img src={quesKey.image} alt="" className={`questionImageHalf delay11 ${isLoading ? '' : 'animate'}`} />
            </div>
        </>     
    )
}

export default memo(QType5);
