import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';

import {
    LoadingGif
} from "../../images";

const QType8 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300);

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            <div className='question active' >
                <p className={`questionName wow delay ${isLoading ? '' : 'animate'}`}>
                    {quesKey.name}
                </p>
                <div className={`questionInput wow delay01 ${isLoading ? '' : 'animate'}`}>
                    <input type="number" placeholder="0" />
                    <span>Years</span>
                </div>
            </div>
        </>     
    )
}

export default memo(QType8);
