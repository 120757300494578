import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';

import {
    LoadingGif
} from "../../images";

const QType20 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return <img src={quesKey.image} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
    }

    return (
        <>
           <div className='question active' >
                
                {
                    (quesKey.name) && (
                        <p className={`questionName medium wow delay ${isLoading ? '' : 'animate'}`}>
                            {parse(quesKey.name)}
                        </p>
                    )
                }
                {/* {
                    (quesKey.name2) && (
                        <p className="questionName tal mbs">
                            {parse(quesKey.name2)}
                        </p>
                    )
                }
                {
                    (quesKey.nameBig) && (
                        <p className="questionName big2">
                            {quesKey.nameBig}
                        </p>
                    )
                } */}
                {/* {
                    (quesKey.text2) && (
                        <div className="questionText2">
                            <p>{parse(quesKey.text2)}</p>
                        </div>
                    )
                } */}
                <img src={quesKey.image} alt="" className={`questionImageFull wow delay01 ${isLoading ? '' : 'animate'}`} />
                {
                    (quesKey.subtitle) && (
                        <div className={`questionSubtitle wow delay11 ${isLoading ? '' : 'animate'}`}>
                            <p>{parse(quesKey.subtitle)}</p>
                        </div>
                    )
                }
                {/* {
                    (quesKey.subtitle2.length > 0) && (
                        <div className="questionSubtitle grey">
                            <p>{parse(quesKey.subtitle2)}</p>
                        </div>
                    )
                } */}
                {/* {
                    (quesKey.text) && (
                        <p className="questionName w500 mbs">
                            {parse(quesKey.text)}
                        </p>
                    )
                }
                {
                    (quesKey.descr) && (
                        <p className="questionDescr w500">
                            {parse(quesKey.descr)}
                        </p>
                    )
                } */}
                {
                    (quesKey.text3) && (
                        <div className={`questionText wow delay21 ${isLoading ? '' : 'animate'}`}>
                            <p>{parse(quesKey.text3)}</p>
                        </div>
                    )
                }
            </div>
        </>     
    )
}

export default memo(QType20);
