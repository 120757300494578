import '../../styles/pages/question.scss';
import 'react-toastify/dist/ReactToastify.css';

import React, {memo, useState} from 'react';
import { Link, navigate } from 'gatsby';


import {
    LoadingGif
} from "../../images";

const QType12 = () => {

    const [isLoading, setLoading] = useState(true);
    setTimeout(function(){
        setLoading(false)
    }, 300);

    return (
        <>
            <div className='question active' >
                <div className="quesEmail">
                    <p className={`questionName medium mbs wow delay ${isLoading ? '' : 'animate'}`}>
                        Sign up with your email!
                    </p>
                    <p className={`questionDescr wow delay01 ${isLoading ? '' : 'animate'}`}>A few more steps and you're done! <br /> We hate paperwork too</p>
                    <div className={`quesEmailInput wow delay11 ${isLoading ? '' : 'animate'}`}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" placeholder="Enter please your email" />
                        <p className="quesEmailRemark">Your information is 100% secure. AEER Platform does not share your personal information with third parties</p>
                    </div>
                </div>
            </div>

            {/* {
                (quesKey.btnName !== '') && (
                    <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                        <span>{quesKey.btnName}</span>
                        <img src={ArrowNext} alt="" />
                    </Link>
                )
            } */}
        </>     
    )
}

export default memo(QType12);
