import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';

import {
    LoadingGif,
    ic1,
    ic2,
    ic3
} from "../../images";

const QType17 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return (
            <div>
                <img src={ic1} style={{display: 'none'}} />
                <img src={ic2} style={{display: 'none'}} />
                <img src={ic3} style={{display: 'none'}} />
                <img src={quesKey.image} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
            </div>
        )
    }

    return (
        <>
            <div className='question active' >
                <p className={`questionBigTitle mbs wow delay ${isLoading ? '' : 'animate'}`}>
                    {parse(quesKey.name)}
                </p>
                <p className={`questionName mbm wow delay01 ${isLoading ? '' : 'animate'}`}>
                    {parse(quesKey.text)}
                </p>
                <div className="questionAdvas">
                    <div className={`questionAdva purple wow2 delay11 center ${isLoading ? '' : 'animate'}`}>
                        <span>Financial growth</span>
                        <img src={ic3} alt="" />
                    </div>
                    <div className={`questionAdva orange wow3 delay21 left ${isLoading ? '' : 'animate'}`}>
                        <span>Achieving goals</span>
                        <img src={ic1} alt="" />
                    </div>
                    <div className={`questionAdva blue wow2 delay31 right ${isLoading ? '' : 'animate'}`}>
                        <span>Career development</span>
                        <img src={ic2} alt="" />
                    </div>
                    
                </div>
                <img src={quesKey.image} alt="" className="questionImageFull" />
            </div>
        </>     
    )
}

export default memo(QType17);
