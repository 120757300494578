import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import CircleProgress from '../CircleProgress';
import parse from 'html-react-parser';

import {
    LoadingGif
} from "../../images";

const QType9 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300);

    setTimeout(() => {
        setLoading1(false);
    }, 4000);

    setTimeout(() => {
        setLoading2(false);
    }, 10000);

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            <div className='question active' >
                <p className={`questionName medium wow delay ${isLoading ? '' : 'animate'}`}>
                    {parse(quesKey.name)}
                </p>
                <div className="questionGraphSmall">
                    <CircleProgress
                        size={165}
                        strokeWidth={16}
                        percentage={100}
                        nextStep={quesKey.nextStep}
                        color="#FFC592">
                    </CircleProgress>
                </div>
                {/* <img src={graph39} alt="" className="questionGraphSmall" /> */}
                <div className="questionList">
                    <ul>
                        <li>Collecting your starting level info</li>
                        <li className={`${loading1 ? 'loading' : ''}`}>Processing your intentions and goals</li>
                        <li className={`${loading2 ? 'loading' : ''}`}>Setting up a personal strategy</li>
                    </ul>
                </div>
            </div>
        </>     
    )
}

export default memo(QType9);
